import React, { useContext, useState } from "react"

import SidebarLayout from '../components/sidebarLayout'
import { ThemeContext } from "../theme-context"
import ContactForm from '../components/contact-form'
import useSiteMetadata from '../hooks/siteMetaData'

function Contact() {
  const { state: { style }, dispatch } = useContext(ThemeContext)
  const { layout } = useSiteMetadata()
  const [alert, setAlert] = useState(null)
  return <SidebarLayout>
    {alert && (
      <div className={`flash flash-${alert.type}`} style={{ marginBottom: 30 }}>
        {alert.message}
      </div>
    )}
    <ContactForm setAlert={setAlert} />
  </SidebarLayout>
}

export default Contact
