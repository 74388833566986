import React, { useContext, useState } from "react"
import fetch from 'unfetch'
import Panel from './panel'
import { ThemeContext } from "../theme-context"
import './contact-form.scss'

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
}

function ContactForm({ setAlert }) {
  const { state: { style } } = useContext(ThemeContext)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const submitForm = (e) => {
    e.preventDefault()

    const encoded = encode({ 'form-name': 'Contact', name, email, message })

    fetch('/contact', {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encoded,
    }).then(() => {
        setAlert({ type: 'success', message: 'Thanks! Your message has been sent.'})
    }).catch(err => {
        console.log('form error: ', err)
        setAlert({ type: 'error', message: 'Whoops! Something went wrong...' })
    }).finally(() => {
        setName('')
        setEmail('')
        setMessage('')
    })
  }

  return (
    <Panel title='Contact Me' subtitle='Send me a note'>
        <form
            id='contact-form'
            method="POST"
            className="ContactForm f4 text-gray"
            name="Contact"
            onSubmit={submitForm}
            data-netlify="true"
            action='/'>
            <input type='hidden' name='form-name' value='Contact' />
            <div className='row' data-thing="test">
                <dl className="form-group col-md-6">
                    <dt><label htmlFor="name">Name</label></dt>
                    <dd><input className="form-control" type="text" name='name' value={name} id="name" onChange={e => setName(e.target.value)} /></dd>
                </dl>
                <dl className="form-group col-md-6">
                    <dt><label htmlFor="email">Email</label></dt>
                    <dd><input className="form-control" type="email" name='email' value={email} id="email" onChange={e => setEmail(e.target.value)} /></dd>
                </dl>
            </div>
            <dl className="form-group">
                <dt><label htmlFor="message">Message</label></dt>
                <dd>
                <textarea className="form-control" id="message" name='message' value={message} onChange={e => setMessage(e.target.value)} />
                </dd>
            </dl>
            <dl className='form-group'>
                <input type='submit' value='Send Message' className='sendBtn' />
            </dl>
        </form>
    </Panel>
  )
}

export default ContactForm
